import { render, staticRenderFns } from "./GenericForm.vue?vue&type=template&id=790a68de&"
import script from "./GenericForm.ts?vue&type=script&lang=ts&"
export * from "./GenericForm.ts?vue&type=script&lang=ts&"
import style0 from "./GenericForm.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Radio: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Radio/Radio.vue').default,SelectField: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/SelectField/SelectField.vue').default,Checkbox: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Checkbox/Checkbox.vue').default,TextField: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/TextField/TextField.vue').default,FormField: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FormField/FormField.vue').default,Trigger: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trigger/Trigger.vue').default})
