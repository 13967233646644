import * as process from 'process';
import Vue from 'vue';
// @ts-ignore 'VueLazyHydration' NPM Package has no types
// (https://github.com/maoberlehner/vue-lazy-hydration/issues/26)
import LazyHydrate from 'vue-lazy-hydration';
import { yieldToMain } from '../../assets/js/utilities/runTask';
import { Breakpoints } from '../../types/breakpoint';
import { Media, MediaLoading, MediaPixelDensity } from '../../types/media';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { equals } from '../../assets/js/utilities/compare';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { MediaProps } from './Media.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Media',
	components: { LazyHydrate },
	mixins: [ClsComponentMixin],
	props: MediaProps,
	data() {
		return {
			mediaObject: this.media,
			previewMediaObject: null as null | Media,
			imageUpdateRequired: false,
			isPreviewImage: false,
			defaultPlaceholder:
				'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
			previewImageSettingsData: {
				divisor: 1,
				quality: 100,
				usePlaceholder: false,
				makePersistent: false,
				maxFileSize: undefined as number | undefined,
			},
			interactionRegistered: false,
			isHydrationNeeded: true,
			allowPreviewImageOptimization: true,
			isLoadingLazy: true,
			isRetina: true,
			objectFitClassname: 'tw-object-cover',
			aspectRatioClassnames: '',
			imgPropWidth: undefined as number | undefined,
			imgPropHeight: undefined as number | undefined,
		};
	},
	computed: {
		isPreviewImageOptimizationActive(): boolean {
			return this.allowPreviewImageOptimization && !this.previewImageSettings?.makePersistent;
		},
		conditionalMediaObject(): Media {
			return this.isPreviewImageOptimizationActive && this.isPreviewImage && this.previewMediaObject
				? this.previewMediaObject
				: this.mediaObject;
		},
		isPlaceholderActive(): boolean {
			return this.conditionalMediaObject.src.includes('/placeholder.');
		},
		imageRatio(): number | undefined {
			return this.aspectRatio?.width && this.aspectRatio?.height
				? this.aspectRatio?.height / this.aspectRatio?.width
				: undefined;
		},
		hasListeners(): boolean {
			return Object.keys(this.$listeners).length > 0;
		},
	},
	watch: {
		media(): void {
			this.updateMedia();
		},
		previewImageOptimization: {
			handler(newValue: boolean): void {
				this.updateAllowPreviewImageOptimization();

				if (!this.previewImageSettings?.makePersistent && !newValue) {
					this.isPreviewImage = false;
				}
			},
		},
	},
	created() {
		if (!this.isPreviewImageOptimizationActive) {
			this.handlePreloads();
		}

		this.updateSettings();
		this.updateImageDimensionProps();
		this.updateMedia();

		this.handlePreviewImageOptimization().then(() => {
			this.handlePreloads();

			this.isLoadingLazy =
				!this.isPreviewImage &&
				!this.previewImageSettings?.usePlaceholder &&
				this.loading === MediaLoading.LAZY;

			this.updateAllowPreviewImageOptimization();

			// Update flag if hydration is needed at the very end of creation
			this.isHydrationNeeded =
				this.hasListeners ||
				!!this.previewImageOptimization ||
				Object.keys(this.previewImageSettings ?? {}).length > 0;
		});
	},
	updated() {
		// Manual trigger of lazyloading after re-render
		// eslint-disable-next-line nuxt/no-env-in-hooks
		if (process.client && this.$el.nodeType !== 8) {
			(window as any).lazySizes?.loader?.unveil(this.$el.querySelector('img'));
		}

		if (
			this.isPreviewImage &&
			!this.isPreviewImageOptimizationActive &&
			this.userAlreadyInteracted()
		) {
			this.isPreviewImage = false;
		}
	},
	methods: {
		handlePreloads() {
			if (!this.preload) return;

			this.$root.$emit('preload', {
				type: 'picture',
				data: this.conditionalMediaObject,
			});
		},
		updateImageDimensionProps() {
			this.imgPropWidth = this.calculateImagePropWidth();
			this.imgPropHeight = this.calculateImagePropHeight();
		},
		calculateImagePropWidth(): number | undefined {
			const widths = [
				...this.mediaObject?.sources.map(({ srcset }) => srcset),
				this.mediaObject.src,
			]
				.map((url) => {
					const rawWidth = url.match(/\/w_?(\d+)\//)?.[1];

					return rawWidth ? parseInt(rawWidth) : Infinity;
				})
				.filter(Number)
				.sort((a, b) => (a < b ? -1 : 1));

			let minimumWidth = Math.min(...widths);

			if (this.BREAKPOINT.IS_DESKTOP) {
				minimumWidth = widths.find((w) => w >= 1024) ?? minimumWidth;
			}

			return isFinite(minimumWidth) ? minimumWidth : this.width || undefined;
		},
		calculateImagePropHeight(): number | undefined {
			const width = this.imgPropWidth;
			const ratio = this.imageRatio;

			return ratio && width ? Math.round(width * ratio) : undefined;
		},
		updateSettings() {
			this.isRetina = process.client
				? (window as any).devicePixelRatio > 1
				: !this.BREAKPOINT.IS_DESKTOP;

			this.objectFitClassname = this.contain ? 'tw-object-contain' : 'tw-object-cover';

			this.aspectRatioClassnames = this.aspectRatio
				? [`tw-aspect-w-${this.aspectRatio.width}`, `tw-aspect-h-${this.aspectRatio.height}`].join(
						' ',
					)
				: '';
		},
		updateAllowPreviewImageOptimization(): void {
			this.allowPreviewImageOptimization =
				!!this.previewImageOptimization &&
				!this.$nuxt?.context?.from &&
				!this.userAlreadyInteracted();
		},
		updateMedia(): void {
			if (equals(this.media, this.mediaObject)) return;

			this.imageUpdateRequired = this.mediaObject?.src !== this.media?.src;

			if (this.imageUpdateRequired) {
				this.mediaObject = this.media;

				if (this.isPreviewImageOptimizationActive) {
					this.generatePreviewMediaObject();
				}
			}
		},
		async handlePreviewImageOptimization(): Promise<void> {
			if (!this.isPreviewImageOptimizationActive) {
				this.isPreviewImage = false;
				return;
			}

			this.isPreviewImage = true;

			await yieldToMain();

			this.updatePreviewImageSettings();

			await yieldToMain();

			this.generatePreviewMediaObject();

			// Check for process client and window object to discover if execution is in browser
			// Strangely process seems not always to be defined
			if (
				(process.client || typeof window !== 'undefined') &&
				!this.previewImageSettingsData?.makePersistent
			) {
				if (this.userAlreadyInteracted()) {
					// Fallback in case user already interacted before everything war ready set up:
					// Give component some time to finish its rendering and then display the images
					setSafeTimeout(() => {
						this.isPreviewImage = false;
					}, 150);

					return;
				}

				this.addInteractionListener();
			}
		},
		addInteractionListener(): void {
			if (typeof window === 'undefined') return;

			// If component has access to nuxt window object ...
			if (window.$nuxt) {
				window.$nuxt.$on('firstUserInteraction', () => {
					this.isPreviewImage = false;
				});

				return;
			}

			// ... otherwise if the osp window object exists ...
			if ((window as any).osp?.userInteraction?.onFirstInteraction) {
				(window as any).osp.userInteraction.onFirstInteraction(() => {
					this.isPreviewImage = false;
				});
				return;
			}

			// ... otherwise fallback - listen to custom event
			window.addEventListener(
				'firstUserInteraction',
				() => {
					this.isPreviewImage = false;
				},
				false,
			);
		},
		userAlreadyInteracted(): boolean {
			// Check for ux user interaction state or when window scrolled assume user scrolled before
			// interaction was started to watch on
			if (this.interactionRegistered) {
				return true;
			}

			this.interactionRegistered =
				typeof window !== 'undefined' &&
				!!(
					(this.$store?.state?.ux?.userInteracted ??
						(window as any).appState?.ux?.userInteracted ??
						true) ||
					(window as any).osp?.userInteraction?.hasUserScrolled()
				);

			return this.interactionRegistered;
		},
		generatePreviewMediaObject(): void {
			if (
				!this.isPreviewImageOptimizationActive ||
				this.previewMediaObject ||
				this.media.src.includes('cloudinary')
			)
				return;

			// Use a helper constant and assign result later to data object when work is done
			// to prevent re-rendering and updates of watchers for every modificataion of object
			let previewMediaObject: Media = { ...this.mediaObject };

			// As long as status of preview image, skip loading and preloading of alternatives
			// until interaction occurred
			previewMediaObject.sources = [];

			if (this.previewImageSettingsData) {
				previewMediaObject = this.optimizeLoadingWidth(previewMediaObject);
				previewMediaObject = this.setRequestedPreviewImageSettings(previewMediaObject);
			}

			this.previewMediaObject = previewMediaObject;
		},
		optimizeLoadingWidth(previewMediaObject: Media): Media {
			const imageWidth = this.getImageWidth();

			if (imageWidth) {
				let optimizedLoadingWidth = imageWidth;

				if (!this.BREAKPOINT.IS_DESKTOP && imageWidth > Breakpoints.TABLET) {
					optimizedLoadingWidth = Breakpoints.TABLET;
				}

				const viewportDivisorWidth = this.getViewportDivisorImageWidth();

				if (viewportDivisorWidth && imageWidth > viewportDivisorWidth) {
					optimizedLoadingWidth = Math.ceil(viewportDivisorWidth);
				}

				previewMediaObject.src = previewMediaObject.src.replace(
					/(\/w_?)\d+\//,
					`$1${optimizedLoadingWidth}/`,
				);
			}

			return previewMediaObject;
		},
		getImageWidth(): number | undefined {
			const displayFactor = this.isRetina ? MediaPixelDensity.RETINA : MediaPixelDensity.DEFAULT;

			return this.imgPropWidth ? this.imgPropWidth * displayFactor : undefined;
		},
		getViewportDivisorImageWidth(): number | undefined {
			if (this.BREAKPOINT.IS_MOBILE)
				return Breakpoints.MOBILE / this.previewImageSettingsData?.divisor;
			else if (this.BREAKPOINT.IS_TABLET)
				return Breakpoints.TABLET / this.previewImageSettingsData?.divisor;
			else if (this.BREAKPOINT.IS_DESKTOP)
				return Breakpoints.DESKTOP / this.previewImageSettingsData?.divisor;

			return undefined;
		},
		setRequestedPreviewImageSettings(previewMediaObject: Media): Media {
			if (!this.isPreviewImageOptimizationActive) {
				return previewMediaObject;
			}

			if (this.previewImageSettingsData?.usePlaceholder === true) {
				previewMediaObject.src = previewMediaObject.src.replace(
					/\/[\w-]{1,255}\.(jpg)$/,
					`/placeholder.$1`,
				);
			}

			const urlParams: string[] = [];

			if (
				this.previewImageSettingsData?.quality &&
				!isNaN(this.previewImageSettingsData.quality) &&
				this.previewImageSettingsData.quality < 100
			) {
				urlParams.push(
					`qlt=${
						this.previewImageSettingsData.quality < 10
							? ',1'
							: this.previewImageSettingsData.quality
					}`,
				);
			}

			if (this.previewImageSettingsData?.maxFileSize) {
				urlParams.push(
					`jpegSize=${
						this.previewImageSettingsData.maxFileSize < 7
							? '7'
							: this.previewImageSettingsData.maxFileSize
					}`,
				);
			}

			if (urlParams.length) {
				previewMediaObject.src = `${previewMediaObject.src}?${urlParams.join('&')}`;
			}

			return previewMediaObject;
		},
		updatePreviewImageSettings(): void {
			this.previewImageSettingsData = {
				...this.previewImageSettingsData,
				...this.previewImageSettings,
			};

			// Validate divisor, otherwise set to default
			if (
				typeof this.previewImageSettingsData.divisor === 'undefined' ||
				this.previewImageSettingsData?.divisor < 1
			) {
				this.previewImageSettingsData.divisor = 1;
			}

			// Validate quality, otherwise set to min / max value
			if (
				typeof this.previewImageSettingsData?.quality === 'undefined' ||
				this.previewImageSettingsData.quality > 100
			) {
				this.previewImageSettingsData.quality = 100;
			} else if (this.previewImageSettingsData.quality < 1) {
				this.previewImageSettingsData.quality = 1;
			}
		},
	},
});
