import { render, staticRenderFns } from "./FlyoutBrands__Filters.vue?vue&type=template&id=04069a09&"
import script from "./FlyoutBrands__Filters.vue?vue&type=script&lang=ts&"
export * from "./FlyoutBrands__Filters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlyoutBrandsFilter: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Filter.vue').default,FlyoutBrandsButton: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Button.vue').default})
