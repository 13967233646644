import { render, staticRenderFns } from "./registration-form.vue?vue&type=template&id=e28b9828&"
import script from "./registration-form.ts?vue&type=script&lang=ts&"
export * from "./registration-form.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Checkbox/Checkbox.vue').default,Ico: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default,GenericFormWrap: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/components/molecules/forms/GenericFormWrap/GenericFormWrap.vue').default,Trigger: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trigger/Trigger.vue').default,Trusties: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trusties/Trusties.vue').default})
