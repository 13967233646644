import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=04a3747e&"
import script from "./layout.ts?vue&type=script&lang=ts&"
export * from "./layout.ts?vue&type=script&lang=ts&"
import style0 from "./layout.scss?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/components/header/header.vue').default,ThreadTaskWrapper: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ThreadTaskWrapper/ThreadTaskWrapper.vue').default,Footer: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/components/footer/footer.vue').default})
