import { render, staticRenderFns } from "./UspBar__Item.vue?vue&type=template&id=45c8e052&"
import script from "./UspBar__Item.vue?vue&type=script&lang=ts&"
export * from "./UspBar__Item.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Ico: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
