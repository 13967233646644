import { render, staticRenderFns } from "./FlyoutBrands__List.vue?vue&type=template&id=f8a7c1f0&"
import script from "./FlyoutBrands__List.vue?vue&type=script&lang=ts&"
export * from "./FlyoutBrands__List.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/SectionTitle/SectionTitle.vue').default,List: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/List/List.vue').default})
