import Vue, { VNode } from 'vue';
import { importDebounce, importLogger } from '../../assets/js/utilities/dynamicImports';
import { Fragment } from '../../components/FragmentComponent/FragmentComponent';
import { IntersectingComponentProps } from './IntersectingComponent.props';

// Component ---------------------------------------------------------------------------------------
export interface IntersectionComponentConfig extends IntersectionObserverInit {
	disabled?: boolean;
	id?: string;
	timeout?: number;
	dummyStyle?: string;
	onScroll?: boolean;
	componentTag?: string;
	containsClsComponent?: boolean;
}

interface asyncMediaComponent extends VNode {
	asyncMeta: {
		tag: string;
	};
}

export default Vue.extend({
	name: 'IntersectingComponent',
	props: IntersectingComponentProps,
	data() {
		return {
			renderContent: false,
			observer: undefined as undefined | IntersectionObserver,
			isIntersecting: false,
			timeoutElement: null,
			debouncedOnScroll: null,
			intersectingComponentsSettings: [] as { id: string }[],
		};
	},
	computed: {
		config(): IntersectionComponentConfig {
			if (this.isIntersecting) {
				return {};
			}

			let configId: string | undefined;
			let component = null;

			if (this.configId) {
				configId = this.configId;
			} else if (this.isEmpty(this.$slots.default)) {
				configId = 'default';
			} else {
				if (Object.keys(this.$slots?.default || {}).length > 1) {
					this.asyncLog(
						'More than one child component found for intersecting-component (lazy loading). Using first for determining configuration',
					);
				}

				const vNode = this.$slots.default?.[0];

				component = vNode ? this.getValidComponent(vNode) : null;
				configId =
					(component?.componentOptions || (component as asyncMediaComponent)?.asyncMeta).tag ??
					undefined;
			}

			const containsClsComponent = 'clsEvent' in (component?.componentOptions?.listeners || {});

			const componentTag = component
				? (component.componentOptions || (component as asyncMediaComponent).asyncMeta).tag
				: undefined;

			return {
				componentTag,
				timeout: this.timeout,
				containsClsComponent,
				...(this.intersectingComponentsSettings.find((item) => item.id === 'default') || {}),
				...(this.intersectingComponentsSettings.find((item) => item.id === configId) || {}),
			};
		},
		isDisabled(): boolean {
			return !!this.config.disabled || !!this.disabled;
		},
		options(): IntersectionObserverInit {
			return {
				rootMargin: `${this.rootMargin || this.config.rootMargin || '15%'}`,
				threshold: this.threshold || this.config.threshold || 0,
			};
		},
	},
	created() {
		const stateSource =
			typeof window !== 'undefined' ? (window as any)?.appState : this.$store?.state;

		this.isIntersecting = stateSource?.userAgent?.isBot || this.isDisabled;

		this.loadIntersectingComponentsSettings();
	},

	mounted() {
		if (!this.isIntersecting) {
			if (this.isDisabled) {
				this.isIntersecting = true;
			} else if (this.config.onScroll) {
				// eslint-disable-next-line require-await
				Vue.nextTick(async () => {
					this.handleContentDimensions();
				});
			} else {
				this.createObserver();
			}
		}
	},

	destroyed() {
		this.destroyObserver();
	},
	methods: {
		getValidComponent(component: VNode): VNode {
			if (
				['App', 'Layout', 'LazyHydrate'].includes(
					(component.componentOptions || (component as any).asyncMeta).tag,
				)
			) {
				if (this.isEmpty(component.componentOptions?.children)) {
					this.asyncLog('Could not find valid component config for intersection config');
				} else {
					const child = component.componentOptions?.children?.[0];
					return child ? this.getValidComponent(child) : component;
				}
			}

			return component;
		},
		asyncLog(message: string, param?: unknown): void {
			importLogger().then(({ default: Logger }) => {
				Logger.info(message, param);
			});
		},
		onScroll() {
			if (this.debouncedOnScroll) {
				document.removeEventListener('scroll', this.debouncedOnScroll);
			}

			this.createObserver();
		},
		loadIntersectingComponentsSettings() {
			this.intersectingComponentsSettings =
				this.$store?.state?.settings?.intersectingComponents || [];
		},

		handleContentDimensions() {
			const isHigherThanViewport = (element: HTMLElement) => {
				if (element && element.offsetHeight < window.innerHeight) {
					this.isIntersecting = true;
				} else if (!this.debouncedOnScroll) {
					importDebounce().then(({ debounce }) => {
						this.debouncedOnScroll = debounce(this.onScroll, 250);
					});
				} else {
					document.addEventListener('scroll', this.debouncedOnScroll);
				}
			};
			// Special use case for e.g. cart page to load footer directly
			// First try to load new Vue specific page content (first selector)
			// and if nothing is found, check if we are on a legacy angular page
			let content: HTMLElement | null = document.querySelector("[class*='osp_master'] > main");

			if (!content) {
				content = document.querySelector("[class='l-content'][data-currency-iso-code]");
			}

			if (!content) {
				return;
			}

			if (content.offsetHeight <= 0) {
				// A hacky way to wait until lazy loaded content inside our selector is ready, so that its height can be calculated
				// Otherwise height will be always 0
				new MutationObserver((_mutations, obs) => {
					if (content && content.offsetHeight) {
						isHigherThanViewport(content);
						obs.disconnect();
					}
				}).observe(content, {
					childList: true,
					subtree: true,
				});
			} else {
				isHigherThanViewport(content);
			}
		},

		createObserver() {
			if (this.observer == null) {
				this.observer = new IntersectionObserver((entries) => {
					this.isIntersecting = entries.some((entry) => entry.isIntersecting);

					if (this.isIntersecting) {
						this.destroyObserver();

						if (this.timeoutElement != null) {
							clearTimeout(this.timeoutElement);

							this.timeoutElement = null;
						}
					}
				}, this.options);

				this.observer.observe(this.$el);
			}
		},

		destroyObserver() {
			if (this.config.onScroll && this.debouncedOnScroll) {
				document.removeEventListener('scroll', this.debouncedOnScroll);
			}

			if (this.observer) {
				this.observer.disconnect();

				this.observer = undefined;
			}
		},

		isEmpty(value: unknown): boolean {
			return (
				value == null ||
				(Array.isArray(value) && value.length === 0) ||
				(typeof value === 'object' && Object.keys(value).length === 0) ||
				(typeof value === 'string' && value.trim().length === 0)
			);
		},
	},
	render(h) {
		let style = { width: '100%', height: this.horizontal ? '100%' : '2000px' };

		if (this.config.dummyStyle) {
			try {
				style = JSON.parse(this.config.dummyStyle);
			} catch (e) {
				this.asyncLog('Found invalid default style JSON for intersection component', this.config);
			}
		}

		if (this.isIntersecting || this.isDisabled) {
			this.$emit('rendering');
			return h(Fragment, this.$slots.default);
		}

		return h('div', { style });
	},
});
