import Vue from 'vue';
import { lock, unlock } from '../../assets/js/utilities/scrollLock';
import { ModalMode } from '../../types/modal';
import { debounce } from '../../assets/js/utilities/debounce';
import KeyboardAvoidingView from '../KeyboardAvoidingView/KeyboardAvoidingView.vue';
import { ModalProps } from './Modal.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Modal',
	components: {
		KeyboardAvoidingView,
	},
	props: ModalProps,
	data() {
		return {
			debouncedKeyListener: null as EventListener | null,
			wrapperMaxHeight: undefined as undefined | number,
		};
	},
	computed: {
		isHeaderVisible(): boolean {
			return !this.isDialog;
		},
		isStandard(): boolean {
			return this.mode === ModalMode.IS_STANDARD;
		},
		isFullscreen(): boolean {
			return this.mode === ModalMode.IS_FULLSCREEN;
		},
		isGallery(): boolean {
			return this.mode === ModalMode.IS_GALLERY;
		},
		isSmall(): boolean {
			return this.mode === ModalMode.IS_SMALL;
		},
		isDialog(): boolean {
			return this.mode === ModalMode.IS_DIALOG;
		},
	},
	watch: {
		async value(value) {
			if (value) {
				// Trigger Safari browser UI update to make sure floating address bar will not cover website content
				if (
					typeof navigator === 'object' &&
					navigator.platform === 'iPhone' &&
					navigator.userAgent.includes('Safari')
				) {
					window.scroll(0, 1);
				}

				await this.$nextTick();
				this.addLockAndListeners();
			} else {
				this.removeLockAndListeners();
				this.$emit('close');
			}
		},
	},
	beforeDestroy() {
		this.removeLockAndListeners();
	},
	methods: {
		handleOverlayClick() {
			if (this.mode !== ModalMode.IS_DIALOG) {
				this.close();
			}
		},
		close() {
			this.$emit('input', false);
		},
		handleBackClick() {
			this.$emit('back');
		},
		keyListener(event: KeyboardEvent) {
			if (event.key === 'Escape') {
				this.close();
			}
		},
		addLockAndListeners() {
			if (this.mode !== ModalMode.IS_DIALOG) {
				if (!this.debouncedKeyListener) {
					this.debouncedKeyListener = debounce(this.keyListener, 250);
				}

				document.body.addEventListener('keydown', this.debouncedKeyListener);
			}

			lock(this.$refs.modalContent as Element);
			lock(this.$refs.keyboardAvoidingView as Element);

			if (process.client && this.lockPageWhenOpened) {
				lock(window.document.getElementsByTagName('body')?.[0]);
			}
		},
		removeLockAndListeners() {
			if (this.mode !== ModalMode.IS_DIALOG) {
				document.body.removeEventListener('keydown', this.debouncedKeyListener as EventListener);
			}

			unlock();
		},
	},
});
