import Vue from 'vue';
import { importRunTask } from '../../assets/js/utilities/dynamicImports';
import { ClsBaseMixin, ClsEventType, IClsEventObject } from './cls-base-mixin';

/**
 * Use this mixin to provide CLS container functionality to a component
 * (A CLS container component is a component which holds several subcomponents and handles their cls state)
 */
export interface IClsContainerMixin {
	handleClsEvent: (clsEventObject: IClsEventObject) => {};
	clsSetVisibilityStatus: (key: string, value: boolean) => {};
	clsStatus: (key: string) => boolean;
}

export const ClsContainerMixin = ClsBaseMixin.extend({
	methods: {
		handleClsEvent(clsEventObject: IClsEventObject) {
			if (!clsEventObject.key || clsEventObject.key.length === 0) return;

			// Avoid registering component names by having the "Lazy" prefix
			const clsKey =
				clsEventObject.key.indexOf('Lazy') === 0
					? clsEventObject.key.replace('Lazy', '')
					: clsEventObject.key;

			if (!this.clsControlShouldHappen) {
				this.clsSetVisibilityStatus(clsKey, true);
				this.clsSkipOptimization();
				return;
			}

			importRunTask().then(({ runTask }) => {
				runTask(() => {
					if (
						(clsEventObject.type === ClsEventType.register &&
							typeof this.clsData.subComponentsVisibility[clsKey] === 'undefined') ||
						clsEventObject.type === ClsEventType.updated
					) {
						this.clsSetVisibilityStatus(clsKey, clsEventObject.value);
					} else if (clsEventObject.type === ClsEventType.finished) {
						this.clsSetVisibilityStatus(clsKey, clsEventObject.value);
						this._checkAllSubcomponentsFinished();
					}
				});
			});
		},
		clsSetVisibilityStatus(key: string, value: boolean): void {
			// Add it via Vue.set to provide reactivity on this property
			Vue.set(
				this.clsData.subComponentsVisibility,
				key,
				this.clsControlShouldHappen ? value : true,
			);
		},
		clsStatus(clsKey: string) {
			return (
				!(clsKey in this.clsData.subComponentsVisibility) ||
				this.clsData.subComponentsVisibility[clsKey] === true
			);
		},
	},
});
