import { render, staticRenderFns } from "./Trusties.vue?vue&type=template&id=88409c82&"
import script from "./Trusties.ts?vue&type=script&lang=ts&"
export * from "./Trusties.ts?vue&type=script&lang=ts&"
import style0 from "./Trusties.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TrustiesItem: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trusties/partials/Trusties__Item.vue').default})
