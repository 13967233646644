import { render, staticRenderFns } from "./touch-redirect-modal.vue?vue&type=template&id=1160bb03&"
import script from "./touch-redirect-modal.ts?vue&type=script&lang=ts&"
export * from "./touch-redirect-modal.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Trigger: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Trigger/Trigger.vue').default,Modal: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Modal/Modal.vue').default})
