import { scrollIntoView } from '@/node_modules/@osp/design-system/assets/js/utilities/scroll';
import { ClsContainerMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-container-mixin';
import { ClsComponentMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-component-mixin';
import { Component, Prop, Vue } from '@/app-utils/decorators';
import { useRoutingStore } from '~/@api/store/routingApi';
import { Messagebox as MessageboxInterface, MessageboxType } from '~/@api/store.types';

@Component({
	mixins: [ClsContainerMixin, ClsComponentMixin],
})
export default class Messagebox extends Vue {
	dismissed = false;

	@Prop({ required: true })
	public data: MessageboxInterface;

	@Prop({ default: true })
	public withScroll: boolean;

	@Prop()
	public scrollContainer: string;

	@Prop()
	public scrollVerticalAlign: 'top' | 'middle';

	get themeIcon() {
		switch (this.data.type) {
			case MessageboxType.ERROR:
				return 'alert';
			case MessageboxType.SUCCESS:
				return 'check';
			case MessageboxType.INFO:
				return 'info';
			default:
				return null;
		}
	}

	mounted() {
		const pageID = useRoutingStore(this.$store).state.spaData.datalayer?.page?.pageInfo?.pageID;

		if (this.withScroll && pageID !== 'notFound') {
			scrollIntoView(this.$el, 'auto', this.scrollVerticalAlign || 'top', this.scrollContainer);
		}
	}

	dismiss() {
		this.$emit('dismiss', this.data);

		this.dismissed = true;
	}
}
