import Vue from 'vue';
import { SectionTitleType } from '../../types/tag';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { SectionTitleProps } from './SectionTitle.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'SectionTitle',
	mixins: [ClsComponentMixin],
	props: SectionTitleProps,
	computed: {
		typeClassnames(): string {
			if (this.type === SectionTitleType.SECONDARY) {
				return 'tw-px-0 tw-text-3 tw-text-darkergrey';
			}

			return 'tw-px-3 tw-text-4 tw-text-black tw-bg-snow';
		},
	},
});
