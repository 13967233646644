import { render, staticRenderFns } from "./List.vue?vue&type=template&id=34d2cc68&"
import script from "./List.ts?vue&type=script&lang=ts&"
export * from "./List.ts?vue&type=script&lang=ts&"
import style0 from "./List.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HtmlFragment: require('/opt/jenkins/workspace/ospbuildtest/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/HtmlFragment/HtmlFragment.vue').default})
